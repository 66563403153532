import { useRef, useEffect } from "react";
import React from "react";

export default function Confirmation() {

  return (
    <div>
      <h2>Good looks, i got you next time</h2>
    </div>
  )
}
