import React from "react"

class About extends React.Component {
    render() {
        return (
            <div>Who's asking?</div>
        )
    }
}

export default About
